<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 14.879159,4.9387042 18.914186,11.957969 22.150613,10.06655 18.115587,3.0893171 Z M 23.327496,9.3940457 24,10.570928 l -1.176883,0.672505 -3.236427,1.849387 -1.134851,0.672504 -0.672504,-1.134851 -0.04203,-0.04203 -1.723292,0.924694 c -0.04203,-0.04203 -0.04203,-0.08406 -0.04203,-0.168126 0.04203,0.252189 0.08406,0.462347 0.08406,0.672504 0,1.891419 -1.513135,3.362522 -3.362522,3.362522 -0.924693,0 -1.723292,-0.336252 -2.311734,-0.882662 l 0.04203,0.04203 c 0.336252,0.29422 0.714536,0.504378 1.134851,0.672504 L 9.2889667,22.76007 H 7.8178634 L 10.381786,16.581436 5.0437829,19.43958 1.4290718,13.218914 13.618214,5.485114 13.029772,4.4763574 14.206655,3.803853 17.443082,1.9124344 18.619965,1.2399301 19.292469,2.4168128 Z M 14.33275,6.6619967 3.2364273,13.681261 5.5481611,17.632224 9.6252189,15.446585 C 9.4150613,15.02627 9.3309983,14.521892 9.3309983,14.059545 c 0,-1.891419 1.4711037,-3.362522 3.3625217,-3.362522 1.176883,0 2.227671,0.630473 2.816112,1.555166 l 1.555167,-0.84063 -2.774081,-4.7495623 z m -1.63923,9.3730293 c 0.714536,0 1.345009,-0.378283 1.723293,-1.008756 0.378283,-0.588442 0.378283,-1.38704 0,-2.017513 C 14.038529,12.420315 13.408056,12 12.69352,12 c -0.756567,0 -1.38704,0.420315 -1.765324,1.008757 -0.378284,0.630473 -0.378284,1.429071 0,2.017513 0.378284,0.630473 1.008757,1.008756 1.765324,1.008756 z m 2.269702,0.462347 c -0.336252,0.336252 -0.714535,0.54641 -1.176882,0.714536 l 2.269702,5.548161 h 1.471103 z M 1.4711033,15.740806 2.8161121,18.094571 3.1523643,18.640981 2.0175131,19.313485 1.6812609,18.725044 0.33625219,16.371279 0,15.782837 1.1768827,15.152364 Z"
  {fill}
/>
