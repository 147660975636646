<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 18.046745,14.602602 v 5.189608 0.467532 L 17.625966,20.493508 11.641553,23.485715 10.566229,24 V 22.784416 18.296107 C 9.3506451,18.810392 8.5558403,19.137665 8.5558403,19.137665 L 4.768829,15.350653 c 0,0 0.3272726,-0.748051 0.8415581,-1.916882 H 1.2155839 0 L 0.51428548,12.358447 3.5064919,6.3740338 3.7402581,5.9532547 H 4.2077903 9.4441516 C 9.537658,5.7662418 9.6311645,5.6259822 9.7714242,5.4389693 13.932461,-0.73245653 20.337653,-0.35843072 23.563626,0.43637411 24.358431,3.6623467 24.732456,10.067539 18.561031,14.228576 c -0.187013,0.14026 -0.327273,0.233766 -0.514286,0.374026 z m -1.496103,0.841558 c -1.44935,0.794805 -3.085713,1.542856 -4.48831,2.197401 v 3.974025 l 4.48831,-2.244155 z M 6.3116855,11.937668 c 0.6545451,-1.402597 1.44935,-3.0389598 2.2441548,-4.48831 h -3.927271 l -2.2441548,4.48831 z m 0.2337661,3.085713 2.3376613,2.337661 0.1870129,-0.04675 C 9.7714242,17.03377 10.706489,16.61299 11.73506,16.145458 13.838955,15.16364 16.223369,13.99481 17.719473,13.012992 22.581808,9.6935131 22.862327,4.7844241 22.301289,1.6987112 19.215576,1.1376725 14.306487,1.3714386 10.987008,6.2805273 10.00519,7.7766308 8.7896064,10.161045 7.8077887,12.218187 7.3402564,13.246758 6.9194774,14.181823 6.638958,14.883121 Z M 17.298693,7.8233841 c 0.607792,0 1.122078,-0.4675326 1.122078,-1.1220778 0,-0.6077919 -0.514286,-1.1220774 -1.122078,-1.1220774 -0.654545,0 -1.122077,0.5142855 -1.122077,1.1220774 0,0.6545452 0.467532,1.1220778 1.122077,1.1220778 z m -2.61818,-1.1220778 c 0,-0.9350645 0.467532,-1.7766225 1.30909,-2.2441548 0.794805,-0.4675322 1.776623,-0.4675322 2.618181,0 0.794805,0.4675323 1.30909,1.3090903 1.30909,2.2441548 0,0.9818181 -0.514285,1.8233761 -1.30909,2.2909084 -0.841558,0.4675323 -1.823376,0.4675323 -2.618181,0 -0.841558,-0.4675323 -1.30909,-1.3090903 -1.30909,-2.2909084 z"
  {fill}
/>
