<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';
  import { v4 as uuidv4 } from 'uuid';
  import { type IconName, icons, getFill, type SvgVariant } from './icons';
  import Svg from './svg.svelte';

  type $$Props = SVGAttributes<SVGElement> & {
    name: IconName;
    variant?: SvgVariant;
    entityId?: string;
    fieldId?: string;
    size?: number;
  };

  export let name: IconName;
  export let variant: SvgVariant = 'default';
  export let entityId = '';
  export let fieldId = '';
  export let size = 24;

  const uuid = uuidv4();

  $: icon = icons[name];
</script>

{#if icon}
  <Svg width={size} height={size} {...$$restProps} {uuid} {entityId} {fieldId}>
    <svelte:component this={icon} fill={getFill(variant, uuid)} />
  </Svg>
{/if}
